.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    /* Adjust the width as needed */
}

.content {
    flex-grow: 1;
}

.copy-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #0056b3;
}


.colName {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 10px;
}

.colValue {
    font-weight: 600;
    font-size: 18px;
    color: #055878;
}

.colResultName {
    margin: 10px 10px;
}