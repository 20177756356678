.title_name {
    font-size: 24px;
    font-weight: 600;
    color: #055878;
}

.content_name {
    font-size: 18px;
    background-color: aliceblue;
    margin: 6px;
    padding: 2px 5px;
    border-radius: 5px;
    color: #007799;
}

.highlight-row {
    background-color: antiquewhite;
}


.highlight-row:hover {
    background-color: antiquewhite !important;
}


.title_name_sub {
    font-size: 20px;
    font-weight: 600;
}