body,
html {
    margin: 0%;
    width: 100%;
}

.fixed-footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

